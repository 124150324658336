<template>
  <div class="pt-[84px] px-6 w-full h-full bg-[#F7F7FC]">
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col flex-grow h-full ">
        <transition name="slide">
          <div class="h-full">
            <div class="!grid_box_shadow h-full ">
              <div class="flex px-3 py-3.5 items-center justify-center bg-white !rounded-t-[8px]">
                <div class="w-full h-full flex justify-start items-center gap-x-2 ">
                  <AnalyticsTypeV2 v-model="selected_analytic_type"></AnalyticsTypeV2>
                </div>
                <div class="w-full flex justify-center items-center">
                  <p class="text-[#3C4549] mr-1 text-lg font-semibold font-poppins leading-none">Analytics</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z"
                          fill="#3C4549"/>
                  </svg>
                </div>
                <div class="w-full flex items-center justify-end">
                  <AnalyticsToggleBtnV2></AnalyticsToggleBtnV2>
                </div>
              </div>
              <hr>
              <div class="flex justify-center items-center py-3.5 px-3 !bg-white !grid_box_shadow  rounded-b-[8px]">
                <div class="flex justify-start items-center gap-x-3">
                  <AnalyticsCampaignsFilterV2
                    v-if="selected_analytic_type === analytic_types.LINK"></AnalyticsCampaignsFilterV2>
                  <AnalyticsLinksFIlterV2 v-if="selected_analytic_type === analytic_types.LINK"
                                          ref="analytics_links_filter"></AnalyticsLinksFIlterV2>
                  <AnalyticsBioLinksFilterV2 v-if="selected_analytic_type === analytic_types.BIO_LINK"
                                             ref="analytics_bio_links_filter"></AnalyticsBioLinksFilterV2>
                  <Button
                    id="filters"
                    type="button"
                    class="!text-[14px] !h-[2.375rem] !font-semibold !text-white !rounded-[8px] bg-[#2560D7] hover:bg-[#1d4ed8]"
                    buttonClass="btn-lg"
                    v-if="getAnalyticsFilters.apply"
                    @click.prevent="applyAnalyticsFilter()">
                    <template v-slot:label>Apply</template>
                  </Button>
                </div>
                <div class=" ml-auto flex items-center justify-end">
                  <DatePicker
                    className="analytics_date_filter"
                    customClass="with_border  !rounded-[8px] !h-[2.375rem]"
                    @input="dateFilter"
                  ></DatePicker>
                </div>
              </div>
            </div>
            <template v-if="getAnalyticsFilters.view_filter === 'overview'">
              <template v-if="!loader">
                <OverviewOfAnalyticsV2 :statistics="statistics" :analytics_type="selected_analytic_type"
                                       :loader="loader"></OverviewOfAnalyticsV2>
              </template>
              <template v-else>
                <div class="flex justify-center items-start py-4 h-screen">
                  <clip-loader :color="'#0095f3'" :size="size"></clip-loader>
                </div>
              </template>
            </template>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import {analyticsTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from "@/common/constants";
import {ANALYTIC_TYPE} from "../../../common/constants";

export default ({
  'name': 'AnalyticsDetail',
  components: {
    AnalyticsTypeV2: () => import('./AnalyticsTypeV2.vue'),
    AnalyticsToggleBtnV2: () => import('./AnalyticsToggleBtnV2.vue'),
    OverviewOfAnalyticsV2: () => import('./detail-view/OverviewOfAnalyticsV2.vue'),
    AnalyticsLinksFIlterV2: () => import('./filters/AnalyticsLinksFIlterV2.vue'),
    AnalyticsCampaignsFilterV2: () => import('./filters/AnalyticsCampaignsFilterV2.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    DatePicker: () => import('@/ui/ui-kit/v2/DatePicker.vue'),
    AnalyticsBioLinksFilterV2: () => import('./filters/AnalyticsBioLinksFilterV2.vue')
  },
  data() {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      currentTab: 'tab1',
      apply_filter: false,
      statistics: {},
      loader: true,
      selected_analytic_type: ANALYTIC_TYPE.LINK,
      analytic_types: ANALYTIC_TYPE
    }
  },
  mounted() {
    let self = this
    setTimeout(function () {
      self.callDetailViewDatePicker()
    }, 0)
  },
  async created() {
    this.$store.commit('SET_ANALYTICS_VIEW_FILTER', 'overview')
  },
  computed: {
    ...mapGetters([
      'getAnalyticsFilters'
    ])
  },
  methods: {
    ...mapActions([
      'countLinks',
      'countBioLinks'
    ]),
    dateFilter(event) {
      this.getAnalyticsFilters.date = event
      this.fetchDetailViewOfAnalytics()
    },
    applyAnalyticsFilter() {
      if (this.getAnalyticsFilters.view_filter === 'all_visitors') {
        this.$refs.realtime.fetchRealtimeStatistics()
        return;
      }
      this.fetchDetailViewOfAnalytics()
    },
    async fetchDetailViewOfAnalytics() {
      this.loader = true
      const type = this.selected_analytic_type === ANALYTIC_TYPE.LINK ? 'detailViewStatistics' : 'bioDetailViewStatistics';
      let res = await this.$store.dispatch(type)
      if (res && res.data.status) {
        this.statistics = res.data.data
      }
      this.loader = false
    },
    callDetailViewDatePicker() {
      const startDate = moment().subtract(29, 'days')
      const endDate = moment()
      let me = this
      $(document).ready(function () {
        const cb = (startDate, endDate, label) => {
          let range = {label: "All Time", value: ""}
          if (label !== " All Time") {
            range = {
              label,
              value: startDate.format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + endDate.format('YYYY-MM-DDTHH:mm:ss')
            }
          }
          me.$store.commit(analyticsTypes.SET_ANALYTICS_DATE, range)
          if (me.getAnalyticsFilters.view_filter === 'all_visitors') {
            me.$refs.realtime.fetchRealtimeStatistics()
          } else {
            me.fetchDetailViewOfAnalytics()
          }
        }
        $('.detailViewDatePicker').daterangepicker({
          opens: 'left',
          maxDate: moment(),
          autoUpdateInput: false,
          startDate,
          endDate,
          ranges: {
            ' All Time': [moment().startOf('day').subtract(1, 'hour'), moment().endOf('day').subtract(1, 'hour')],
            ' Today': [moment(), moment()],
            ' Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            ' Last 7 Days': [moment().subtract(6, 'days'), moment()],
            ' Last 30 Days': [moment().subtract(29, 'days'), moment()],
            ' This Month': [moment().startOf('month'), moment().endOf('month')],
            ' Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            ' Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
          }
        }, cb)
        cb(startDate, endDate, ' Last 30 Days')
      })
    }
  },
  watch: {
    'getAnalyticsFilters.brand._id'(value) {
      this.$set(this.getAnalyticsFilters, 'apply', true)
      if (this.selected_analytic_type === this.analytic_types.BIO_LINK) {
        this.$refs.analytics_bio_links_filter.searchLinksDirectly()
      }
    },
    'getAnalyticsFilters.campaign._id'(value) {
      this.$set(this.getAnalyticsFilters, 'apply', true)
      this.$refs.analytics_links_filter.searchLinksDirectly()
    },
    'getAnalyticsFilters.link._id'(value) {
      this.$set(this.getAnalyticsFilters, 'apply', true)
    },
    'selected_analytic_type'() {
      this.applyAnalyticsFilter()
    }
  }
})
</script>
